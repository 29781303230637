













































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsVerticalPercentageBar from '@/components/VsVerticalPercentageBar/Index.vue'
import CampaignNameModal from '@/modules/campaigns/components/CampaignNameModal/Index.vue'
import VsEmailStatisticsFullEngagement from '@/modules/campaigns/components/VsEmailStatisticsFullEngagement/Index.vue'
import VsEmailStatisticsFullQuality from '@/modules/campaigns/components/VsEmailStatisticsFullQuality/Index.vue'
import VsSmsStatisticsFullEngagement from '@/modules/campaigns/components/VsSmsStatisticsFullEngagement/Index.vue'
import CampaignReportModal from '@/modules/campaigns/components/CampaignReportModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    deleteCampaign,
    duplicateCampaign,
    getCampaign,
    updateCampaign,
} from '@/api/consoleApi/campaigns'
import { get } from 'lodash'
import {
    ICampaign,
    CampaignStatusEnum,
} from '@/api/consoleApi/dto/campaigns.dto'
import { AppModule } from '@/store/modules/app'
import axios from 'axios'
import { generateEmailSentReport, getCampaignStatistics, getEmailSentReport } from '@/api/consoleApi/reports'
import moment from 'moment'
import { UserModule } from '@/store/modules/user'
import html2pdf from 'html2pdf.js'
import { formatNumber } from '@/utils/formatter'
import { downloadCsv } from '@/utils/export'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'

@Component({
    name: 'VsCampaignStatistics',
    components: {
        VsContainer,
        VsSectionHeader,
        VsLoader,
        CampaignNameModal,
        VsConfirm,
        VsDropdownButton,
        VsVerticalPercentageBar,
        VsEmailStatisticsFullEngagement,
        VsEmailStatisticsFullQuality,
        VsTabsHeading,
        VsSmsStatisticsFullEngagement,
        CampaignReportModal,
    },
})
export default class extends Vue {
    private loading = false
    private campaign: ICampaign | null = null
    private statistics: {
        sent: {
            total: number
            error: number
            sent_percentage: number
        }
        delivery: {
            total: number
            delivery_percentage: number
        }
        bounce: {
            total: number
            hard: number
            soft: number
            bounce_percentage: number
        }
        spam: {
            total: number
            last: null | number
            spam_percentage: number
        }
        unsubscriptions: {
            total: number
            last: null | number
            unsubscription_percentage: number
        }
        open: {
            total: number
            unique: number
            unique_mpp: number
            last: null | number
            or_percentage: number
        }
        click: {
            total: number
            unique: number
            last: null | number
            ctr_percentage: number
            ctor_percentage: number
        }
    }| null = null

    get tabs () {
        return [
            {
                label: 'Riepilogo',
                id: 'campaignEdit',
                to: {
                    name: 'campaignEdit',
                },
            },
            {
                label: 'Statistiche',
                id: 'campaignStatistics',
                disabled: this.campaign?.status === 'Draft',
                to: {
                    name: 'campaignStatistics',
                },
            },
        ]
    }

    get activeTab () {
        return this.$route.name
    }

    get activeClick () {
        if (!this.statistics || !this.statistics.click.last) return false
        return moment().diff(moment.unix(this.statistics.click.last), 'days') <= 7
    }

    get activeOpen () {
        if (!this.statistics || !this.statistics.open.last) return false
        return moment().diff(moment.unix(this.statistics.open.last), 'days') <= 7
    }

    get deliveryHeight () {
        if (!this.statistics) return 0
        return this.statistics.sent.sent_percentage
    }

    get openHeight () {
        if (!this.statistics) return 0
        return (this.deliveryHeight / 100) * (this.statistics.delivery.delivery_percentage / 100) * 100
    }

    get clickHeight () {
        if (!this.statistics) return 0
        return (this.openHeight / 100) * (this.statistics.open.or_percentage / 100) * 100
    }

    get clickSmsHeight () {
        if (!this.statistics) return 0
        return (this.deliveryHeight / 100) * (this.statistics.delivery.delivery_percentage / 100) * 100
    }

    $refs: any

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get isEditable () {
        if (!this.campaign) return false
        return (this.campaign.status === CampaignStatusEnum.Draft || this.campaign.status === CampaignStatusEnum.Paused)
    }

    get isReadyAndNeverSent () {
        if (!this.campaign) return false
        return ((this.campaign.status === CampaignStatusEnum.Ready || this.campaign.status === CampaignStatusEnum['Pending Approval']) && this.campaign.sent_start === '0000-00-00 00:00:00')
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get isCampaignScheduled () {
        if (!this.campaign) return false
        return this.campaign.schedule_type !== 'Not Scheduled'
    }

    get campaignStatusColor () {
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'info'
            case CampaignStatusEnum.Sending:
                return 'primary'
            case CampaignStatusEnum.Sent:
                return 'success'
            case CampaignStatusEnum.Failed:
                return 'alert'
            case CampaignStatusEnum.Paused:
                return 'warning'
            case CampaignStatusEnum['Pending Approval']:
            case CampaignStatusEnum.Draft:
            default:
                return 'grey'
        }
    }

    get campaignStatusIcon () {
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'clock'
            case CampaignStatusEnum.Sending:
                return 'outgoing'
            case CampaignStatusEnum.Paused:
                return 'pause'
            case CampaignStatusEnum['Pending Approval']:
                return 'clock'
            case CampaignStatusEnum.Sent:
                return 'double-check'
            case CampaignStatusEnum.Failed:
                return 'error'
            case CampaignStatusEnum.Draft:
                return 'edit'
        }
    }

    get canDeleteCampaign () {
        if (!this.campaign) return false
        return (this.campaign.status === CampaignStatusEnum.Draft || this.campaign.status === CampaignStatusEnum.Failed || this.campaign.status === CampaignStatusEnum.Sent)
    }

    get isBooster () {
        return this.campaign?.type === 'Booster'
    }

    get isEmail () {
        if (!this.campaign) return false
        return (this.campaign.content_type === 'email')
    }

    get isSms () {
        if (!this.campaign) return false
        return (this.campaign.content_type === 'sms')
    }

    get isAbSplit () {
        if (!this.campaign) return false
        return this.campaign.content_type === 'split_test'
    }

    get user () {
        return UserModule.user
    }

    get hasFullStatistics () {
        return this.user.configuration.rules.campaignStatisticFull
    }

    get canRepotExport () {
        return this.user.configuration.rules.reportExport
    }

    get canEmailSentReport () {
        return this.user.configuration.rules.emailSentReport
    }

    get sentThresholds () {
        return get(AppModule.consoleConf, 'sentThresholds', { unsubscription: 3, bounce: 7, spam: 0.1 })
    }

    get isUnsubscriptionUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.unsubscriptions.unsubscription_percentage < this.sentThresholds.unsubscription
    }

    get isSpamUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.spam.spam_percentage < this.sentThresholds.spam
    }

    get isBounceUnderThreshold () {
        if (!this.statistics) return false
        return this.statistics.bounce.bounce_percentage < this.sentThresholds.bounce
    }

    get showEmailSentReport () {
        if (!this.campaign) return false
        return this.campaign.content_type === 'email' && (this.campaign.status === CampaignStatusEnum.Paused || this.campaign.status === CampaignStatusEnum.Sent)
    }

    async beforeMount () {
        this.getCampaign()
        this.getCampaignStatistics()
    }

    private async resetCampaign () {
        if (!this.campaign) return
        await this.getCampaign()
    }

    private async openReport () {
        await this.getCampaign()
        if (this.campaign?.sent_report === 'Completed') {
            await this.getEmailSentReport()
            return
        }

        if (this.campaign?.sent_report === 'NotAvailable' && this.campaign.status === CampaignStatusEnum.Paused) {
            try {
                await this.generateReport()
                await this.getCampaign()
            } catch (e) {
                console.log(e)
                // non è possibile generare il report
            }
        }
        this.openCampaignReportModal()
    }

    private openCampaignReportModal () {
        this.$refs.campaignReportModal.openModal(this.campaign)
    }

    private async generateReport () {
        try {
            await generateEmailSentReport(this.campaignId)
        } catch (e) {
            console.log(e)
        }
    }

    private async getEmailSentReport () {
        this.loading = true
        try {
            const resp = await getEmailSentReport(this.campaignId)
            downloadCsv(resp.data, `${this.campaign?.name}_report_inviate`)
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Il report è stato generato ma non è più disponibile per il download',
                aspect: VsToastAspectEnum.neutral,
            })
            console.log(e)
        }
        this.loading = false
    }

    @Watch('campaignId', { immediate: false })
    private async getCampaign () {
        this.loading = true
        try {
            const resp = await getCampaign(this.campaignId)
            this.campaign = resp.data.data
        } catch (e) {
            const errorCode = get(e, 'response.status', '')
            if (errorCode === 404) {
                this.$router.replace({
                    name: 'campaignsIndex',
                })
            }
            console.log(e)
        }
        this.loading = false
    }

    @Watch('campaignId', { immediate: false })
    private async getCampaignStatistics () {
        this.loading = true
        try {
            const resp = await getCampaignStatistics(this.campaignId)
            this.statistics = resp.data.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    async updateName (e: any) {
        if (!this.campaign) return
        try {
            await updateCampaign(this.campaignId, {
                name: e.name,
            })
            this.$refs.campaignNameModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.updateNameCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
            })
            this.getCampaign()
        } catch (e) {
            const toast = this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.generalUpdateCampaignError'),
                aspect: VsToastAspectEnum.alert,
            })
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    const message = get(e, 'response.data.message.name', [''])
                    if (message[0] === 'The name may not be greater than 250 characters.') {
                        toast.heading = this.$t('campaigns.editCampaign.toasts.maxLengthCampaignNameError')
                    }
                }
            }
            this.$refs.campaignNameModal.loading = false
            console.log(e)
        }
    }

    private getMinMaxBarsHeight (height: number) {
        if (height < 0) return 0
        if (height > 100) return 100
        return height
    }

    async downloadPdf () {
        const opt = {
            filename: `${this.campaign?.name}_overview.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                // foreignObjectRendering: true,
                // scale: 1,
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                // hotfixes: 'px_scaling',
            },
        }
        await html2pdf().from(this.$refs.basePrintContent.$el).set(opt).save()
    }

    private async cloneCampaign () {
        this.loading = true
        try {
            await duplicateCampaign(this.campaignId)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Campagna duplicata con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            const errorCode = get(e, 'response.data.message', 0)
            let message = ''
            if (errorCode === 'You don\'t have abTest permission') {
                message = 'Non puoi duplicare la campagna perchè non hai la funzionalità A/B split attiva'
            }
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                message,
                heading: 'Errore durante la duplicazione della campagna',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async deleteCampaign () {
        try {
            await this.$refs.confirmDeleteCampaign.openConfirm()
            this.$refs.confirmDeleteCampaign.closeConfirm()
        } catch (e) {
            this.$refs.confirmDeleteCampaign.closeConfirm()
            return
        }
        this.loading = true
        try {
            await deleteCampaign(this.campaignId)
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.deleteCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
            })
            this.$router.push({
                name: 'campaignsIndex',
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.deleteCampaignError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private formatNumber (value: number) {
        return formatNumber(value)
    }
}
